var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UiPageHeader',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.$t('Roles')))]},proxy:true},{key:"page-specific-content",fn:function(){return [(!_vm.isCreatingRole)?_c('div',{staticClass:"tw-ml-auto"},[_c('ui-button',{attrs:{"color":"primary"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onCreateRole.apply(null, arguments)}}},[_vm._v(" Create Role ")])],1):_vm._e(),(_vm.isCreatingRole)?_c('div',{staticClass:"tw-flex tw-ml-auto"},[_c('div',{staticClass:"tw-mr-4"},[_c('ui-button',{attrs:{"color":"white"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onCancel.apply(null, arguments)}}},[_c('span',[_vm._v("Cancel")])])],1),_c('div',[_c('ui-button',{attrs:{"color":"primary","disabled":false},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onSave.apply(null, arguments)}}},[(_vm.isSaving)?_c('ui-loading',{staticClass:"tw-text-lg tw-align-top"}):_c('span',[_vm._v("Save")])],1)],1)]):_vm._e()]},proxy:true}])}),(!_vm.isCreatingRole)?_c('div',{staticClass:"tw-p-6 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-border tw-border-neutral-100 tw-rounded-lg tw-overflow-hidden"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.roles,"isLoading":!!_vm.isLoading,"show-action-column":"","no-data-message":"There are no roles to display."},scopedSlots:_vm._u([{key:"td-action",fn:function(ref){
var role = ref.item;
return [_c('td',[(role.editable)?_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-end"},[_c('fa',{staticClass:"tw-mr-2 tw-w-20 tw-cursor-pointer",attrs:{"color":"primary","icon":"fa-solid fa-pen-to-square"},on:{"click":function($event){return _vm.openEditPage(role)}}})],1):_vm._e()])]}}],null,false,286327702)})],1)]):_c('div',{staticClass:"tw-p-6"},[_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-w-72 tw-mr-10"},[_c('span',{staticClass:"tw-block tw-text-sm tw-font-medium tw-text-neutral-500 tw-mb-1"},[_vm._v("Name")]),_c('ui-text-edit',{attrs:{"name":"name"},model:{value:(_vm.currentRole.name),callback:function ($$v) {_vm.$set(_vm.currentRole, "name", $$v)},expression:"currentRole.name"}})],1),_c('div',{staticClass:"tw-w-96 tw-mr-10"},[_c('span',{staticClass:"tw-block tw-text-sm tw-font-medium tw-text-neutral-500 tw-mb-1"},[_vm._v("Description")]),_c('ui-text-edit',{attrs:{"name":"description"},model:{value:(_vm.currentRole.description),callback:function ($$v) {_vm.$set(_vm.currentRole, "description", $$v)},expression:"currentRole.description"}})],1)]),_c('div',{staticClass:"tw-mt-4 tw-border tw-border-neutral-100 tw-rounded-lg tw-overflow-hidden"},[_c('ui-data-table',{attrs:{"headers":_vm.scopeHeaders,"items":_vm.scopeDefinitions,"isLoading":!!_vm.isScopesLoading,"no-data-message":"There are no scopes to display."},scopedSlots:_vm._u([{key:"td-entity",fn:function(ref){
var scope = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex"},[(scope.subEntity === null)?_c('div',[_vm._v(" "+_vm._s(scope.entity)+" ")]):_c('div',{staticClass:"tw-ml-3"},[_vm._v(" "+_vm._s(scope.subEntity)+" ")])])])]}},{key:"td-read",fn:function(ref){
var scope = ref.item;
return [(scope.readEnabled)?_c('td',[_c('ui-checkbox',{model:{value:(scope.readValue),callback:function ($$v) {_vm.$set(scope, "readValue", $$v)},expression:"scope.readValue"}})],1):_vm._e()]}},{key:"td-create",fn:function(ref){
var scope = ref.item;
return [(scope.createEnabled)?_c('td',[_c('ui-checkbox',{model:{value:(scope.createValue),callback:function ($$v) {_vm.$set(scope, "createValue", $$v)},expression:"scope.createValue"}})],1):_vm._e()]}},{key:"td-update",fn:function(ref){
var scope = ref.item;
return [(scope.updateEnabled)?_c('td',[_c('ui-checkbox',{model:{value:(scope.updateValue),callback:function ($$v) {_vm.$set(scope, "updateValue", $$v)},expression:"scope.updateValue"}})],1):_vm._e()]}},{key:"td-delete",fn:function(ref){
var scope = ref.item;
return [(scope.deleteEnabled)?_c('td',[_c('ui-checkbox',{model:{value:(scope.deleteValue),callback:function ($$v) {_vm.$set(scope, "deleteValue", $$v)},expression:"scope.deleteValue"}})],1):_vm._e()]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }